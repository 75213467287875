import styled from 'styled-components';
import MainLink from '@/components/global/typography/links/MainLink';

const DocusRedLinkButton = styled(MainLink)`
  &&& {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    background-color: #e95d53;
    border-color: #e95d53;
    border-radius: 4px;
    color: #fff;
    padding: 12px 24px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-shrink: 0;
    transition: 0.2s;
    &:hover {
      background-color: #d2544b !important;
      border-color: #d2544b;
      color: #fff;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

export default DocusRedLinkButton;
