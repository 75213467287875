'use client';
import React, { MouseEventHandler } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { SIGN_IN_URL } from '@/utils/config';
import { Analytics } from '@/libs/GoogleAnalytics';
import DarkLogo from '@/components/global/logo/DarkLogo';
import MainMenu from '@/components/common/header/MainMenu';
import HeaderButtons from '@/components/common/header/HeaderButtons';
import ButtonLink from '@/components/global/typography/links/ButtonLink';

interface IDesktopMenuProps {
  isUserLoggedIn: boolean;
}

const DesktopMenuStyled = styled.div`
  @media (max-width: 1220px) {
    display: none;
  }
`;

const DesktopMenu = ({ isUserLoggedIn }: IDesktopMenuProps) => {
  const handleSignInBtnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
    Analytics.sendLoginStartEventToGA();
  };

  return (
    <DesktopMenuStyled>
      <Row align="middle">
        <Col>
          <DarkLogo />
        </Col>
        <Col>
          <MainMenu />
        </Col>
        <Col style={{ display: 'flex', marginLeft: 'auto' }}>
          {!isUserLoggedIn ? (
            <HeaderButtons isUserLoggedIn={isUserLoggedIn} />
          ) : (
            <ButtonLink onClick={handleSignInBtnClick} href={SIGN_IN_URL}>
              My Dashboard
            </ButtonLink>
          )}
        </Col>
      </Row>
    </DesktopMenuStyled>
  );
};

export default DesktopMenu;
