import styled from 'styled-components';
import { Menu } from 'antd';

const CustomMenu = styled(Menu)`
  &&& {
    margin: 0 !important;
    border-inline-end: 0 !important;

    > .ant-menu-item {
      padding: 16px 0 !important;
      border-radius: 0 !important;
      width: 100% !important;
      margin: 0;
      height: auto !important;
    }

    .ant-menu-submenu {
      border-radius: 0 !important;
      margin: 0 !important;
      padding: 16px 0 !important;
      height: auto !important;
    }

    .ant-menu-submenu {
      .ant-menu-item {
        border-bottom: none;
        padding: 8px 16px 8px 32px !important;
        border-radius: 0 !important;
        width: 100% !important;
        margin: 0;
        height: auto !important;
        &:first-child {
          padding: 24px 16px 8px 32px !important;
        }
      }
    }

    .ant-menu-submenu .ant-menu-item > span {
      height: auto !important;
      line-height: 0 !important;
    }

    .ant-menu-submenu {
      .ant-menu-submenu-title {
        border-radius: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
        width: auto !important;
        height: auto;
        .ant-menu-submenu-arrow {
          inset-inline-end: 0;
        }
      }
    }

    .ant-menu-submenu .ant-menu-item:last-of-type {
      padding-bottom: 0 !important;
    }

    .ant-menu-submenu-selected > .ant-menu-submenu-title,
    .ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
      color: #7d7f8b;
    }

    .solutions-collapse-icon,
    .resources-collapse-icon {
      width: 13px;
      height: 18px;
    }

    .ant-menu-title-content {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #272a3d;
    }
  }
`;

export default CustomMenu;
