'use client';

import React, { useEffect, useRef, useState } from 'react';
import { Layout } from 'antd';
import { isLogged } from '@/utils/helper';
import HeaderStyled from '@/components/common/header/style';
import MobileMenu from '@/components/common/header/MobileMenu';
import DesktopMenu from '@/components/common/header/DesktopMenu';
import PrimaryContainer from '@/components/global/containers/PrimaryContainer';

const { Header } = Layout;

interface MainLayoutProps {
  headerBg?: string;
}

const MainHeader: React.FC<MainLayoutProps> = ({ headerBg = '#fff' }) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isShadowVisible, setIsShadowVisible] = useState(false);

  setTimeout(() => setIsUserLoggedIn(isLogged()), 2000);

  const toggleShadow = () => {
    let scroll = window.scrollY;
    setIsShadowVisible(scroll > 80);
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleShadow);

    return () => {
      window.removeEventListener('scroll', toggleShadow);
    };
  }, []);

  return (
    <HeaderStyled
      className={`${isShadowVisible ? 'shadow' : ''}`}
      bgColor={headerBg}
    >
      <PrimaryContainer className={'header-wrapper'}>
        <Header className={'header'}>
          <MobileMenu user={isUserLoggedIn} />
          <DesktopMenu isUserLoggedIn={isUserLoggedIn} />
        </Header>
      </PrimaryContainer>
    </HeaderStyled>
  );
};

export default MainHeader;
