'use client';

import styled from 'styled-components';
import React, { CSSProperties } from 'react';

interface IPrimaryContainerStyledProps {
  $bgColor?: string;
  className?: string;
  style?: CSSProperties;
}

// TODO: reminder for changing margin strategy
const PrimaryContainerStyled = styled.section<IPrimaryContainerStyledProps>`
  width: 100%;
  max-width: 1220px;
  margin: 0 auto 112px;
  padding: 0 40px;
  background: ${(props) => props.$bgColor || 'transparent'};
  @media (max-width: 768px) {
    padding: 0 16px 16px;
    margin: 0 auto 80px;
  }

  @media (min-width: 1280px) {
  }
`;

interface FullWidthContainerProps extends IPrimaryContainerStyledProps {
  children: React.ReactNode;
  bgColor?: string;
}

const PrimaryContainer = ({
  children,
  className,
  style,
  bgColor,
}: FullWidthContainerProps) => {
  return (
    <PrimaryContainerStyled
      className={className}
      $bgColor={bgColor}
      style={style}
    >
      {children}
    </PrimaryContainerStyled>
  );
};

export default PrimaryContainer;
