import React from 'react';
import HeaderButtons from '@/components/common/header/HeaderButtons';

interface IMobileMenuFooterProps {
  user: boolean;
  handleOnClose: Function;
}

const MobileMenuFooter = ({ user }: IMobileMenuFooterProps) => {
  return (
    <div className={'mobile-menu-footer'}>
      <div className={'flex flex-col-reverse justify-center items-center '}>
        <HeaderButtons isUserLoggedIn={user} />
      </div>
    </div>
  );
};

export default MobileMenuFooter;
