import { lowerCaseStringAndRemoveSpaces } from '@/utils/helper';
import ControlParams = Gtag.ControlParams;
import EventParams = Gtag.EventParams;
import CustomParams = Gtag.CustomParams;
import GtagCommands = Gtag.GtagCommands;
import EventNames = Gtag.EventNames;

type GtagEventParamsType =
  | ControlParams
  | EventParams
  | CustomParams
  | undefined;

type EventNameType = EventNames | (string & {});

export const GA_TAG_MANAGER_ID = process.env.DOCUS_GOOGLE_TAG_MANAGER_ID!;
export const GA_MEASUREMENT_ID = process.env.DOCUS_GOOGLE_MEASUREMENT_ID!;

export class Analytics {
  private static GA_MEASUREMENT_ID: string = GA_MEASUREMENT_ID || '';
  private static USER_ID: string | undefined = undefined;
  /** Analytics initialization
   *
   */
  static init(): void {
    if (typeof window === 'undefined') return;
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer?.push(arguments);
    }

    window.gtag = gtag;
    window.gtag('js', new Date());
  }

  /** General function configured for sending events to GA
   *
   * @param payload
   * @private
   *
   */
  private static sendPayloadToGA(payload: GtagCommands['event']): void {
    if (!this.GA_MEASUREMENT_ID) {
      console.error(
        'Google Analytics is not initialized. Call init() before sending events.',
      );
      return;
    }

    let payloadData: any = {
      ...payload[1],
    };

    payloadData.user_id = this.USER_ID;

    window.gtag('event', payload[0], payloadData);
  }

  /** Public user login
   *
   * @param userId
   * @param eventParams
   *
   */
  static sendPublicUserInitEventToGA(
    userId: string | undefined,
    eventParams?: GtagEventParamsType,
  ): void {
    this.USER_ID = userId;
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'User init',
      ...eventParams,
    };

    this.sendPayloadToGA(['user_init', { ...payload, user_id: userId }]);
  }

  /** Add UserId to dataLayer
   *
   * @param userId
   *
   */
  static addUserIdToGADataLayer(userId: string): void {
    if (!this.USER_ID) {
      this.USER_ID = userId;
      this.sendPublicUserInitEventToGA(userId);
    }

    if (typeof window === 'undefined') return;
    window.dataLayer?.push({
      user_id: userId,
    });
  }

  /** Track and send page view change event
   *
   * @param url
   *
   */
  static sendPageViewEventToGA(url: string): void {
    window.gtag('config', this.GA_MEASUREMENT_ID, {
      page_path: url,
    });
  }

  /** Sign in (also Google sign in) button click
   *
   * @param eventParams
   *
   */
  static sendLoginStartEventToGA(eventParams?: GtagEventParamsType): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'User sign in start',
      ...eventParams,
    };

    this.sendPayloadToGA(['login_start', payload]);
  }

  /** Send sign up event while verifying email address
   *
   * @param eventParams
   *
   */
  static sendSignUpStartEventToGA(eventParams?: GtagEventParamsType): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'User sign up start',
      ...eventParams,
    };

    this.sendPayloadToGA(['sign_up_start', payload]);
  }

  /** Send sign up event while verifying email address
   *
   * @param eventParams
   *
   */
  static sendRedirectToAiHealthAssistantPageEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'Redirect to ai health assistant page',
      ...eventParams,
    };

    this.sendPayloadToGA(['redirect_to_ai_health_assistant_page', payload]);
  }

  /** Pricing Card Free Subscription Plan Click
   *
   * @param eventParams
   *
   */
  static sendFreePricingStartEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'ecommerce',
      event_label: 'Pricing Free card click',
      ...eventParams,
    };

    this.sendPayloadToGA(['free_pricing_card_sign_up_start', payload]);
  }

  /** Pricing Card Pro Subscription Plan Click
   *
   * @param eventParams
   *
   */
  static sendProPricingStartEventToGA(eventParams?: GtagEventParamsType): void {
    const payload: GtagEventParamsType = {
      event_category: 'ecommerce',
      event_label: 'Pricing Pro card click',
      ...eventParams,
    };

    this.sendPayloadToGA(['pro_pricing_card_sign_up_start', payload]);
  }

  /** Pricing Card Premium Subscription Plan Click
   *
   * @param eventParams
   *
   */
  static sendPremiumPricingStartEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'ecommerce',
      event_label: 'Pricing Premium card click',
      ...eventParams,
    };

    this.sendPayloadToGA(['premium_pricing_card_sign_up_start', payload]);
  }

  /** Medical experts list view
   *
   * @param eventParams
   *
   */
  static sendMedicalExpertsListViewEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'View Medical experts list',
      ...eventParams,
    };

    this.sendPayloadToGA(['view_item_list', payload]);
  }

  /** Medical experts list item view
   *
   * @param eventParams
   *
   */
  static sendMedicalExpertsListItemViewEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'View Medical experts list item',
      ...eventParams,
    };

    this.sendPayloadToGA(['view_item', payload]);
  }

  /** Send modal open/close state
   *
   * @param eventName
   * @param eventLabel
   * @param eventParams
   *
   */
  static sendModalStateToGA(
    eventName: EventNameType,
    eventLabel: string,
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: eventLabel,
      ...eventParams,
    };

    this.sendPayloadToGA([
      `${lowerCaseStringAndRemoveSpaces(eventName)}`,
      payload,
    ]);
  }

  /** Send footer links click event
   *
   * @param eventName
   * @param eventLabel
   * @param eventParams
   *
   */
  static sendFooterLinkClickEventToGA(
    eventName: EventNameType,
    eventLabel: string,
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: eventLabel,
      ...eventParams,
    };

    this.sendPayloadToGA([
      `${lowerCaseStringAndRemoveSpaces(eventName)}`,
      payload,
    ]);
  }

  /** Send Logo click event
   *
   * @param pageName
   * @param eventParams
   *
   */
  static sendLogoClickEventToGA(
    pageName: string,
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: `Logo click on ${pageName} page`,
      ...eventParams,
    };

    this.sendPayloadToGA([`logo_click_on_${pageName}_page`, payload]);
  }

  /** Send custom event to GA
   *
   * @param eventName
   * @param eventCategory
   * @param eventLabel
   * @param eventParams
   *
   */
  public static sendCustomEventToGA(
    eventName: string,
    eventCategory: string,
    eventLabel: string,
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: eventCategory,
      event_label: eventLabel,
      ...eventParams,
    };

    this.sendPayloadToGA([eventName, payload]);
  }

  /* --------------------- Symptom Checker Start --------------------- */

  /** Send Symptom Checker Start button click event
   *
   * @param eventParams
   *
   */
  static sendStartSymptomCheckEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'Start Symptom Check button click event',
      ...eventParams,
    };

    this.sendPayloadToGA(['start_symptom_check', payload]);
  }

  /** Send Symptom Assessment Start button click event
   *
   * @param eventParams
   *
   */
  static sendStartSymptomAssessmentEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'Start Symptom Assessment button click event',
      ...eventParams,
    };

    this.sendPayloadToGA(['start_symptom_assessment', payload]);
  }

  /** Send Symptom Checker step view event
   *
   * @param stepTitle
   * @param eventParams
   *
   */
  static sendSymptomCheckerStepViewEventToGA(
    stepTitle: string,
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: `Symptom Checker ${stepTitle} step view event`,
      ...eventParams,
    };

    this.sendPayloadToGA([
      `symptom_checker_${stepTitle.toLowerCase().replaceAll(' ', '_')}_step_view`,
      payload,
    ]);
  }

  static sendSymptomCheckerUserInfoFormSubmitEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'Symptom Checker User Info form submit event',
      ...eventParams,
    };

    this.sendPayloadToGA(['symptom_checker_user_info_form_submit', payload]);
  }

  /** Send Symptom Checker User info submit form event
   *
   * @param eventParams
   *
   */
  static sendSymptomCheckerQuestionsFormSubmitEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'Symptom Checker Questions form submit event',
      ...eventParams,
    };

    this.sendPayloadToGA(['symptom_checker_questions_form_submit', payload]);
  }

  /** Send Symptom Checker Redirect to Dashboard event
   *
   * @param eventParams
   *
   */
  static sendSymptomCheckerRedirectToDashboardEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'Redirect to dashboard from symptom checker',
      ...eventParams,
    };

    this.sendPayloadToGA([
      'redirect_to_dashboard_from_symptom_checker',
      payload,
    ]);
  }

  /* --------------------- Symptom Checker End --------------------- */

  /* --------------------- Knowledge Base Start --------------------- */

  /** Send Knowledge Base Start button click event
   *
   * @param eventParams
   *
   */
  static sendKnowledgeBaseSearchButtonClickEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: 'Knowledge Base Search button click event',
      ...eventParams,
    };

    this.sendPayloadToGA(['knowledge_base_search_button_click', payload]);
  }

  /* --------------------- Knowledge Base End --------------------- */

  /* --------------------- Partners lending start --------------------- */

  /** Send Partners Form Modal view event
   *
   * @param eventParams
   *
   */
  static sendPartnersFormModalViewEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: `View partners form modal`,
      ...eventParams,
    };

    this.sendPayloadToGA([`view_partners_form_modal`, payload]);
  }

  /** Send Partners Form submit event
   *
   * @param eventParams
   *
   */
  static sendPartnersFormSubmitEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: `Partners Form Submit event`,
      ...eventParams,
    };

    this.sendPayloadToGA([`partners_landing_form_submit`, payload]);
  }

  /** Send Partners Try Demo Chat Button Click event
   *
   * @param eventParams
   *
   */
  static sendPartnersTryDemoChatButtonClickEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: `Partners landing page try demo chat button click`,
      ...eventParams,
    };

    this.sendPayloadToGA([
      `partners_landing_try_demo_chat_button_click`,
      payload,
    ]);
  }

  /** Send Partners Demo Chat Send Message Button Cli`ck event
   *
   * @param eventParams
   *
   */
  static sendPartnersDemoChatSendMessageButtonClickEventToGA(
    eventParams?: GtagEventParamsType,
  ): void {
    const payload: GtagEventParamsType = {
      event_category: 'engagement',
      event_label: `Partners landing page demo chat send message button click`,
      ...eventParams,
    };

    this.sendPayloadToGA([
      `partners_landing_demo_chat_send_message_button_click`,
      payload,
    ]);
  }
  /* --------------------- Partners lending End --------------------- */
}
