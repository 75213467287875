import React, { MouseEventHandler, useState } from 'react';
import { Col, ConfigProvider, Row } from 'antd';
import Button from 'antd/es/button';
import DarkLogo from '@/components/global/logo/DarkLogo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@awesome.me/kit-b89bd90d95/icons/classic/light';
import MobileMenuStyled from '@/components/common/header/MobileMenu/style';
import MobileNavigation from '@/components/common/header/MobileMenu/MobileNavigation';
import MobileMenuHeader from '@/components/common/header/MobileMenu/MobileMenuHeader';
import MobileMenuFooter from '@/components/common/header/MobileMenu/MobileMenuFooter';

type MobileMenuProps = {
  user: boolean;
};

const MobileMenu: React.FC<MobileMenuProps> = ({ user }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  const handleOnOpen: MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
    setMenuOpened(true);
    setTimeout(() => {
      document.body.classList.add('overflow-hidden');
    }, 500);
  };

  const handleOnClose = () => {
    setMenuOpened(false);
    if (document.body.classList.contains('overflow-hidden')) {
      document.body.classList.remove('overflow-hidden');
    }
  };

  return (
    <MobileMenuStyled>
      <div className={'mobile-menu-view'}>
        <div className={`mobile-menu ${menuOpened ? 'open' : ''}`}>
          <div className={'mobile-menu-layout'}>
            <div className={'mobile-menu-content'}>
              <MobileMenuHeader handleOnClose={handleOnClose} />
              <MobileNavigation handleOnClose={handleOnClose} user={user} />
            </div>

            <MobileMenuFooter user={user} handleOnClose={handleOnClose} />
          </div>
        </div>
        <Row align={'middle'} className={'mobile-header'}>
          <Col xs={12}>
            <DarkLogo />
          </Col>
          <Col xs={12} className={'text-right burger-wrapper'}>
            <ConfigProvider wave={{ disabled: true }}>
              <Button
                aria-label={'Open menu'}
                icon={<FontAwesomeIcon icon={faBars} size={'2x'} />}
                onClick={handleOnOpen}
                className={'align-middle burger-menu'}
              />
            </ConfigProvider>
          </Col>
        </Row>
      </div>
    </MobileMenuStyled>
  );
};

export default MobileMenu;
