import React from 'react';
import MainImage from '@/components/global/MainImage';
import MainLink from '@/components/global/typography/links/MainLink';

const MobileMenuLogo: React.FC = () => {
  return (
    <MainLink href={'/'}>
      <div style={{ width: '104px' }} className={'relative'}>
        <MainImage
          src={'/docus-dark-logo.svg'}
          loading={'eager'}
          priority={true}
          alt={'Docus: AI-Powered Health Platform'}
        />
      </div>
    </MainLink>
  );
};

export default MobileMenuLogo;
