import styled from 'styled-components';
import MainLink from '@/components/global/typography/links/MainLink';

const MobileMenuDefaultLink = styled(MainLink)`
  &&& {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #272a3d;

    &:hover {
      color: #525564 !important;
    }
  }
`;

export default MobileMenuDefaultLink;
