import styled from 'styled-components';
import MainLink from '@/components/global/typography/links/MainLink';

const ButtonLink = styled(MainLink)`
  &&& {
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #272a3d !important;
    padding: 8px 16px;
    transition: 0.2s;
    &:hover {
      color: #d2544b !important;
    }
  }
`;

export default ButtonLink;
