import Cookies from 'js-cookie';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { ObjectLiteralType } from '@/types';

export function isLogged() {
  return !!Cookies.get('loggedUser');
}

export const calculateYearsOfExperience = (startYear: number): number => {
  const now: Date = new Date();
  const start: Date = new Date(startYear, 0); // Assuming startYear is in YYYY format

  const diffInMilliseconds: number = now.getTime() - start.getTime();
  const millisecondsInYear: number = 1000 * 60 * 60 * 24 * 365.25; // Account for leap years

  const yearsOfExperience: number = Math.floor(
    diffInMilliseconds / millisecondsInYear,
  );
  return yearsOfExperience;
};

export const removeTags = (str: string) => {
  if (str === null || str === '') return str;
  else str = str.toString();
  // Remove HTML tags
  str = str.replace(/<[^>]*>/g, '');

  return str.replace(/&nbsp;/g, ' ').trim();
};

export const lowerCaseStringAndRemoveSpaces = (string: string) => {
  return string.toLowerCase().replaceAll(' ', '_');
};

export const getGeneratedPublicUserId = async () => {
  const fpPromise = FingerprintJS.load();
  const fp = await fpPromise;
  const identity = await fp.get();
  return identity.visitorId;
};

export const transformUrlObjectToString = function (obj: ObjectLiteralType) {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export const generateSlug = (text: string) => {
  return text
    .toLowerCase()
    .replace(/[\s_]+/g, '-') // Replace spaces and underscores with a single hyphen
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars except hyphens
    .replace(/\-\-+/g, '-');
};
