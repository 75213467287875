import styled from 'styled-components';
import React, { CSSProperties } from 'react';

interface ContainerProps {
  $bgColor?: string;
  className?: string;
  style?: CSSProperties;
}

const PrimaryContainerFluidStyled = styled.div<ContainerProps>`
  width: 100%;
  background: ${(props) => props.$bgColor || 'transparent'};
  margin: 0 auto 112px;

  @media (max-width: 768px) {
    padding: 0 0 16px;
    margin: 0 auto 80px;
  }
`;

interface FullWidthContainerProps extends ContainerProps {
  children: React.ReactNode;
  bgColor?: string;
}

const PrimaryContainerFluid = ({
  children,
  bgColor,
  className,
  style,
}: FullWidthContainerProps) => {
  return (
    <PrimaryContainerFluidStyled
      className={className}
      $bgColor={bgColor}
      style={style}
    >
      {children}
    </PrimaryContainerFluidStyled>
  );
};

export default PrimaryContainerFluid;
