import React, { MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MobileMenuLogo from '@/components/global/logo/MobileMenuLogo';
import { faXmarkLarge } from '@awesome.me/kit-b89bd90d95/icons/classic/regular';

interface IMobileMenuHeaderProps {
  handleOnClose: MouseEventHandler<HTMLDivElement>;
}

const MobileMenuHeader = ({ handleOnClose }: IMobileMenuHeaderProps) => {
  return (
    <div className={'mobile-menu-header'}>
      <div onClick={handleOnClose}>
        <MobileMenuLogo />
      </div>
      <div className={'close-icon-wrapper'} onClick={handleOnClose}>
        <FontAwesomeIcon style={{ color: '#272A3D' }} icon={faXmarkLarge} />
      </div>
    </div>
  );
};

export default MobileMenuHeader;
