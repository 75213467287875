'use client';

import { Layout } from 'antd';
import React, { ReactNode } from 'react';
import DynamicFooter from '@/components/dynamic/DynamicFooter';
import MainHeader from '@/components/common/header/MainHeader';
import DynamicCookiePolicy from '@/components/dynamic/DynamicCookiePolicy';

const { Content } = Layout;

interface MainLayoutProps {
  children: ReactNode;
  headerBg?: string;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  headerBg = '#fff',
}) => {
  return (
    <Layout
      style={{
        maxWidth: '100%',
        margin: '0 auto',
        backgroundColor: '#fff',
        overflowX: 'hidden',
      }}
    >
      <MainHeader headerBg={headerBg} />
      <Content className={'main'}>{children}</Content>
      <DynamicFooter />
      <DynamicCookiePolicy />
    </Layout>
  );
};

export default MainLayout;
