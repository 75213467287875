import React, { MouseEventHandler } from 'react';
import { Analytics } from '@/libs/GoogleAnalytics';
import { SIGN_IN_URL, SIGN_UP_URL } from '@/utils/config';
import ButtonLink from '@/components/global/typography/links/ButtonLink';
import DocusRedLinkButton from '@/components/global/buttons/DocusRedLinkButton';

interface IHeaderButtonsProps {
  isUserLoggedIn: boolean;
}

const HeaderButtons = ({ isUserLoggedIn }: IHeaderButtonsProps) => {
  const handleSignInBtnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
    Analytics.sendLoginStartEventToGA();
  };

  const handleSignUpBtnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
    Analytics.sendSignUpStartEventToGA();
  };

  return (
    <>
      <ButtonLink onClick={handleSignInBtnClick} href={SIGN_IN_URL}>
        Sign in
      </ButtonLink>
      <DocusRedLinkButton onClick={handleSignUpBtnClick} href={SIGN_UP_URL}>
        Get Started for Free
      </DocusRedLinkButton>
    </>
  );
};

export default HeaderButtons;
