import styled from 'styled-components';
import PrimaryContainerFluid from '@/components/global/containers/PrimaryContainerFluid';

interface IHeaderStyledProps extends React.HTMLProps<HTMLDivElement> {
  bgColor?: string;
}

const HeaderStyled = styled(PrimaryContainerFluid)<IHeaderStyledProps>`
  &&& {
    position: fixed;
    margin-bottom: 0;
    padding-bottom: 0;
    z-index: 9;
    .header-wrapper {
      padding-bottom: 0;
      margin-bottom: 0;
      .header {
        padding: 24px 0;
        line-height: 0;
        height: 100px;
        background-color: ${(props) => props.bgColor};
      }
    }

    &.shadow {
      z-index: 100;
      box-shadow: 0 5px 20px 0 #272a3d0d;
    }

    @media (max-width: 1220px) {
      position: unset;
      z-index: unset;
      &.shadow {
        z-index: 100;
        box-shadow: none;
      }
    }

    @media (max-width: 768px) {
      .header-wrapper {
        margin-bottom: 60px;
        .header {
          height: 82px;

          .mobile-menu-view {
            .bm-overlay {
              top: 0;
              left: 0;
              background-color: #fff !important;
            }
          }
        }
      }
    }
  }
`;

export default HeaderStyled;
