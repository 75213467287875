export const EXPERT_BIO_CHARACTER_COUNT = 500;
export const EXPERT_BIO_ITEMS_COUNT = 5;

export const APP_URL = process.env.APP_URL;
export const BLOG_URL = `${APP_URL}/blog`;
export const SYMPTOMS_GUIDE_URL = `${APP_URL}/symptoms-guide`;
export const KNOWLEDGE_BASE_URL = `${APP_URL}/knowledge-base`;
export const SYMPTOM_CHECKER_URL = `${APP_URL}/symptom-checker`;
export const SYMPTOM_CHECKER_FOR_WOMEN_URL = `${SYMPTOM_CHECKER_URL}/symptom-checker-for-women`;
export const SYMPTOM_CHECKER_FOR_ADULTS_URL = `${SYMPTOM_CHECKER_URL}/symptom-checker-for-adults`;
export const SYMPTOM_CHECKER_FOR_CHILD_URL = `${SYMPTOM_CHECKER_URL}/child-symptom-checker`;
export const DASHBOARD_URL = process.env.DASHBOARD_URL!;
export const SIGN_UP_URL = DASHBOARD_URL + '/auth/signup';
export const SIGN_IN_URL = `${DASHBOARD_URL}/auth/signin`;
export const GLOSSARY_VIEW_URL = `${APP_URL}/glossary`;
