import styled from 'styled-components';

const MobileMenuStyled = styled.div`
  &&&& {
    display: none;
    @media (max-width: 1220px) {
      display: block;
      width: 100%;
      height: auto;
      .ant-menu-submenu-open {
        .ant-menu-submenu-title,
        .ant-menu-title-content {
          color: #7d7f8b;
        }
      }
      .mobile-menu-view {
        .mobile-menu {
          background-color: #fff;
          position: fixed;
          width: 100%;
          height: 100%;
          z-index: 0;
          top: 0;
          left: 1250px;
          transition: 1s;
          padding: 24px;
          &.open {
            z-index: 5;
            left: 0;
            & ~ .mobile-header {
              .burger-wrapper {
                .burger-menu {
                  z-index: 2;
                }
              }
            }
          }

          .mobile-menu-layout {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            .mobile-menu-header {
              display: flex;
              justify-content: space-between;
              width: 100%;
              align-items: center;
              margin-bottom: 24px;
              .close-icon-wrapper {
                &:hover {
                  cursor: pointer;
                }
              }
            }
            .mobile-menu-footer {
            }
          }
        }
        .mobile-header {
          .burger-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .burger-menu {
              display: flex;
              align-items: center;
              justify-content: center;
              position: fixed;
              z-index: 9;
              padding: 0;
              border: none;
              font-size: 16px;
              color: #000;
              width: 52px;
              height: 52px;
              border-radius: 50%;
              background-color: #f5f5f5;
              box-shadow: none;
            }
          }
        }
      }
    }
  }
`;

export default MobileMenuStyled;
