import styled from 'styled-components';
import MainLink from '@/components/global/typography/links/MainLink';

const MenuLink = styled(MainLink)`
  &&& {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #272a3d;

    &:hover {
      color: #7d7f8b;
    }
  }
`;

export default MenuLink;
